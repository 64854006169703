import { useState, useEffect } from "react";

export function usePersistentState(key, defaultValue, secure = false) {
    const [state, setState] = useState(() => {
        const storedValue = localStorage.getItem(key);
        if (!storedValue) return defaultValue;
        try {
            return secure ? JSON.parse(atob(storedValue)) : JSON.parse(storedValue);
        } catch (error) {
            console.error(`Error parsing ${key} from storage`, error);
            return defaultValue;
        }
    });

    useEffect(() => {
        try {
            const value = secure ? btoa(JSON.stringify(state)) : JSON.stringify(state);
            localStorage.setItem(key, value);
        } catch (error) {
            console.error(`Error saving ${key} to storage`, error);
        }
    }, [key, state, secure]);

    return [state, setState];
}
