import { useContext } from "react";
import { Routes, Route } from "react-router-dom";
import { PublicRoute } from "./PublicRoute";
import { PrivateRoute } from "./PrivateRoute";
import { Login, Page404 } from "../auth";
import { SidebarNavbar, WorkstationSelection } from "../components/ElementsUi/";
import UserContext from "../context/UserContext";

export const AppRoute = () => {
    const { workstationActive } = useContext(UserContext);
    return (
        <>
            <Routes>
                <Route
                    path="login/*"
                    element={
                        <PublicRoute>
                            <Routes>
                                <Route path="/*" element={<Login />} />
                            </Routes>
                        </PublicRoute>
                    }
                />

                <Route
                    path="/*"
                    element={
                        <PrivateRoute>
                            {localStorage.getItem("workstation") === null &&
                                workstationActive === null ? (
                                    <WorkstationSelection />
                                ) : (
                                    <SidebarNavbar />
                                )}
                        </PrivateRoute>
                    }
                />

                {/* <Routes>
                            <Route path="/" element={<Home />} />

                            {Links.map((ruta) =>
                                !ruta.children ? (
                                    <Route
                                        key={ruta.ruta}
                                        path={`${ruta.to}`}
                                        element={
                                            <Occ
                                                ruta={ruta.ruta}
                                                title={ruta.text}
                                            />
                                        }
                                    />
                                ) : (
                                    ruta.children.map((children) => (
                                        <Route
                                            key={children.ruta}
                                            path={`${children.to}`}
                                            element={
                                                <SelectorGenerico
                                                    ruta={children.ruta}
                                                    title={children.text}
                                                />
                                            }
                                        />
                                    ))
                                )
                            )}

                        </Routes> */}
                        <Route path="/error" element={<Page404 />} />
            </Routes>
        </>
    );
};
